import React, { HTMLProps } from 'react'
import { Link } from 'gatsby'
import * as styles from './Link.module.scss'

interface LinkProps extends HTMLProps<HTMLAnchorElement> {
	text: string
	url?: string
	newTab?: boolean
	gatsby?: boolean
}

export default ({ url, text, newTab = false, gatsby = false, ...attributes }: LinkProps) => {
	if (gatsby && url) {
		return (
			<Link to={url} className={styles.link}>
				{text}
			</Link>
		)
	}

	if (newTab) {
		return (
			<a
				href={url}
				className={styles.link}
				target='_blank'
				rel='noopener noreferrer'
				{...attributes}
			>
				{text}
			</a>
		)
	}

	if (url) {
		return (
			<a href={url} className={styles.link} {...attributes}>
				{text}
			</a>
		)
	}

	return (
		<span className={styles.link} {...attributes}>
			{text}
		</span>
	)
}
