import React, { useEffect, useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import BrandLogo from '../../images/svg/spektro_servisas_logo.svg'
import BrandIcon from '../../images/svg/brand_icon.svg'
import * as styles from './NavigationBar.module.scss'

const absoluteNavBarProperties = {
	navigationPosition: 'absolute',
	navigationTopPosition: 15,
	navigationWidth: 80,
	navigationBarBorderRadius: 5
}

const fixedNavBarProperties = {
	navigationPosition: 'fixed',
	navigationTopPosition: 0,
	navigationWidth: 100,
	navigationBarBorderRadius: 0
}

export default () => {
	const [navBarProperties, setNavBarProperties] = useState(absoluteNavBarProperties)
	const [toggle, setToggle] = useState(false)
	const { sm } = useBreakpoint()

	const handleScrollEvent = () => {
		if (window.scrollY >= 1) {
			setNavBarProperties(fixedNavBarProperties)
		} else {
			setNavBarProperties(absoluteNavBarProperties)
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScrollEvent)
		return () => window.removeEventListener('scroll', handleScrollEvent)
	}, [])

	return (
		<div
			className={styles.navBarWrapper}
			style={{
				position: navBarProperties.navigationPosition as 'absolute' | 'fixed',
				top: `${navBarProperties.navigationTopPosition}px`,
				width: `${navBarProperties.navigationWidth}%`
			}}
		>
			<div className={styles.topLine} />
			<Navbar
				className={styles.navBar}
				style={{
					borderRadius: `${navBarProperties.navigationBarBorderRadius}px`
				}}
				bg='light'
				expand='lg'
				expanded={toggle}
				onToggle={(expanded) => setToggle(expanded)}
			>
				<Link className={styles.navBrand} to='/'>
					{sm ? (
						<BrandIcon className={styles.brandIcon} />
					) : (
						<BrandLogo className={styles.brandLogo} />
					)}
				</Link>
				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse className='justify-content-end'>
					<Nav data-nav className={styles.nav}>
						<Link className={styles.link} to='/' onClick={() => setToggle(false)}>
							Pagrindinis
						</Link>
						<Link className={styles.link} to='/service' onClick={() => setToggle(false)}>
							Servisas
						</Link>
						<Link className={styles.link} to='/logistics' onClick={() => setToggle(false)}>
							Logistika
						</Link>
						{/* <Link className={styles.link} to='/contacts'> */}
						{/*	Kontaktai */}
						{/* </Link> */}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	)
}
