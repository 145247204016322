import React from 'react'
import Slider from 'react-slick'
import Shell from '../../images/partners/shell_logo.png'
import Addinol from '../../images/partners/addinol_logo.png'
import Romasta from '../../images/partners/romasta_logo.png'
import Bridgestone from '../../images/partners/bridgestone_logo.png'
import Autokada from '../../images/partners/autokada_logo.png'
import RomastaParts from '../../images/partners/romasta_parts_logo.png'
import Autodara from '../../images/partners/autodara_logo.png'
import BeneTrucks from '../../images/partners/benetrukcs_logo.png'
import EuroPart from '../../images/partners/europart_logo.png'
import Laneda from '../../images/partners/laneda_logo.png'
import Wurth from '../../images/partners/wurth_logo.png'
import VarstuPasaulis from '../../images/partners/varstu_pasaulis_logo.png'
import * as styles from './Partners.module.scss'
import 'slick-carousel/slick/slick.css'

export default () => (
	<Slider
		className={styles.slickSlide}
		autoplay
		autoplaySpeed={400}
		infinite
		initialSlide={0}
		slidesToScroll={1}
		speed={3000}
		pauseOnHover
		pauseOnFocus
		variableWidth
		dots={false}
		arrows={false}
		centerMode
	>
		<div>
			<a href='https://oil.jungent.eu/lt/' target='_blank' rel='noopener noreferrer'>
				<img src={Shell} alt='Shell' />
			</a>
		</div>
		<div>
			<a href='https://addinol.lt/' target='_blank' rel='noopener noreferrer'>
				<img src={Addinol} alt='Addinol' />
			</a>
		</div>
		<div>
			<a href='https://romasta.lt/' target='_blank' rel='noopener noreferrer'>
				<img src={Romasta} alt='Romasta' />
			</a>
		</div>
		<div>
			<a href='https://www.bridgestone.lt/' target='_blank' rel='noopener noreferrer'>
				<img src={Bridgestone} alt='Bridgestone' />
			</a>
		</div>
		<div>
			<a href='http://www.autokada.lt/lt/' target='_blank' rel='noopener noreferrer'>
				<img src={Autokada} alt='Autokada' />
			</a>
		</div>
		<div>
			<a
				href='https://romasta.lt/sunkiojo-transporto-serviso-paslaugos/'
				target='_blank'
				rel='noopener noreferrer'
			>
				<img src={RomastaParts} alt='Romasta' />
			</a>
		</div>
		<div>
			<a href='https://www.autodara.lt/' target='_blank' rel='noopener noreferrer'>
				<img src={Autodara} alt='Autodara' />
			</a>
		</div>
		<div>
			<a href='http://benetrucks.lt/' target='_blank' rel='noopener noreferrer'>
				<img src={BeneTrucks} alt='Bene trucks' />
			</a>
		</div>
		<div>
			<a href='https://www.europart.net/lt' target='_blank' rel='noopener noreferrer'>
				<img src={EuroPart} alt='Euro parts' />
			</a>
		</div>
		<div>
			<a href='http://www.laneda.lt/lt' target='_blank' rel='noopener noreferrer'>
				<img src={Laneda} alt='Laneda' />
			</a>
		</div>
		<div>
			<a href='http://www.wurth.lt/' target='_blank' rel='noopener noreferrer'>
				<img src={Wurth} alt='Wurth' />
			</a>
		</div>
		<div>
			<a href='https://www.varztupasaulis.lt/' target='_blank' rel='noopener noreferrer'>
				<img src={VarstuPasaulis} alt='Varstu pasaulis' />
			</a>
		</div>
	</Slider>
)
