import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { IoMdCall, IoMdMail, IoMdPin } from 'react-icons/all'
import classNames from 'classnames'
import * as styles from './ContactUs2.module.scss'
import Link from '../Elements/Link/Link'

interface ContactUsProps {
	phoneNumber: string
	email: string | string[]
	address: string
	addressUrl: string
}

export default ({ phoneNumber, email, address, addressUrl }: ContactUsProps) => (
	<div className={classNames(styles.container, 'mt-5')}>
		<Row>
			<Col>
				<p className={styles.description}>
					Mūsų komanda yra visada pasiruošusi jums padėti ir atsakyti į visus klausimus
				</p>
			</Col>
		</Row>
		<Row>
			<Col md={4} sm={12}>
				<div className={styles.otherContent}>
					<div className={styles.circle}>
						<IoMdCall />
					</div>
					<span className={styles.contactTitle}>Telefonas</span>
					<div>
						<Link url={`tel:${phoneNumber}`} text={phoneNumber} />
					</div>
				</div>
			</Col>
			<Col md={4} sm={12}>
				<div className={styles.otherContent}>
					<div className={styles.circle}>
						<IoMdMail />
					</div>
					<span className={styles.contactTitle}>El. paštas</span>
					{typeof email === 'string' ? (
						<div>
							<Link url={`mailto:${email}`} text={email} />
						</div>
					) : (
						email.map((emailAddress) => (
							<Link
								key={`email-${emailAddress}`}
								url={`mailto:${emailAddress}`}
								text={emailAddress}
							/>
						))
					)}
				</div>
			</Col>
			<Col md={4} sm={12}>
				<div className={styles.otherContent}>
					<div className={styles.circle}>
						<IoMdPin />
					</div>
					<span className={styles.contactTitle}>Adresas</span>
					<Link url={addressUrl} text={address} newTab />
				</div>
			</Col>
		</Row>
	</div>
)
