import * as React from 'react'
import Layout from './src/components/Layout/Layout'
import Provider from './src/store/Provider'

export const wrapRootElement = ({ element }) => {
	return <Provider>{element}</Provider>
}

export const wrapPageElement = ({ element, props }) => {
	return <Layout path={props.path}>{element}</Layout>
}
