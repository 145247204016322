// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--3mBiF";
export var content = "Footer-module--content--X-MR6";
export var title = "Footer-module--title--1TUHK";
export var list = "Footer-module--list--2LLaq";
export var address = "Footer-module--address--1yVan";
export var socialContainer = "Footer-module--socialContainer--DhdRu";
export var socialWrapper = "Footer-module--socialWrapper--3SyLr";
export var socialTitle = "Footer-module--socialTitle--3M1L3";
export var socialLink = "Footer-module--socialLink--AOo3D";
export var socialIcon = "Footer-module--socialIcon--32EdI";
export var facebook = "Footer-module--facebook--2-_XM";
export var linkedin = "Footer-module--linkedin--1IScD";
export var copyright = "Footer-module--copyright--2GL1M";