import * as React from 'react'
import { Container } from 'react-bootstrap'
import classNames from 'classnames'
import Footer from '../Footer/Footer'
import NavigationBar from '../NavigationBar/NavigationBar'
import SectionHeading from '../SectionHeading/SectionHeading'
import ContactUs2 from '../ContactUs/ContactUs2'
import * as contactStyles from '../ContactUs/ContactUs2.module.scss'
import Partners from '../Partners/Partners'
import * as styles from './Layout.module.scss'

interface Props {
	children: React.ReactNode
}

export default ({ children }: Props) => {
	return (
		<div className={styles.containerGrey}>
			<NavigationBar />
			{children}
			<Container className={classNames(contactStyles.containerContactUs, 'pt-5')}>
				<SectionHeading title='Susisiekite su mumis' />
				<ContactUs2
					phoneNumber='+370 650 90303'
					email='servisas@spektroservisas.lt'
					address='V. A. Graičiūno g. 32B, Vilnius'
					addressUrl='https://www.google.com/maps/place/Spektro+servisas/@54.6106399,25.1548982,17z/data=!3m1!4b1!4m5!3m4!1s0x46dded2d8211cb9f:0xda4b6a381925ec36!8m2!3d54.6105835!4d25.1569579'
				/>
			</Container>
			<Container fluid className='pt-5'>
				<SectionHeading title='Mūsų partneriai' />
				<Partners />
			</Container>
			<Footer />
		</div>
	)
}
