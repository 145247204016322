import { CHANGE_HEADER_IMAGE } from './actionTypes'

export interface State {
	header: {
		image?: string
		title?: string
		description?: string
		viewHeight?: number
		isButtonDownEnabled?: boolean
		elementRef?: any
		parallax?: boolean
	}
}

export interface Action {
	type: string
	payload?: any
}

export const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case CHANGE_HEADER_IMAGE:
			return { ...state, header: action.payload }

		default:
			return state
	}
}
