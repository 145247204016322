import * as React from 'react'
import { Dispatch, ReactNode, useReducer } from 'react'
import { Action, reducer, State } from './reducer'

interface ContextProps {
	state: State
	dispatch: Dispatch<Action>
}

export const initialState: State = {
	header: {
		image: undefined,
		parallax: false
	}
}

export const Context = React.createContext<ContextProps>({
	state: initialState,
	dispatch: () => null
})

interface ProviderProps {
	children: ReactNode
}

const Provider = ({ children }: ProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState)
	return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
}

export default Provider
